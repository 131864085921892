<template>
  <div 
    class="view-claim-auth" 
    @keyup="onKeyUp">
    <form 
      class="view-claim-auth__body"
      @submit="onConfirm">
      <!-- Header -->
      <header class="view-claim-auth__header">
        <h1 class="view-claim-auth__title">{{ $t('user-portal.user_self_claim_title')}}</h1>
        <p class="view-claim-auth__descr">{{ $t('user-portal.user_self_claim_descr')}}</p>
      </header>

      <section 
        class="view-claim-auth__login flow" 
        v-if="hasError">
        <!-- Error banner -->
        <ui-banner
          class="layout-claim__banner"
          :intent="$pepper.Intent.DANGER"
          :title="claimError.title">
          <template #description>
            <div class="layout-claim__banner-descr">
              <p>{{ claimError.description }}</p>
            </div>
          </template>
        </ui-banner>
      </section> 

      <!-- Login -->
      <section 
        class="view-claim-auth__login flow" 
        v-if="!done">
        <forms-input
          :errors="emailErrors"
          :placeholder="$t('user-portal.placeholder_email')"
          type="email"
          @input="onRemoveEmailErrors"
          v-model="email"
          required
        >{{ $t('user-portal.user_email') }}</forms-input>

        <div
          class="view-claim-auth__attributes"
          v-if="nft && nft.claimableAttributesMode === 'user'">
          <component
            v-for="(component, i) in attributesComponents"
            :key="component.key"
            :is="component.is"
            :errors="getErrors(`attributes.${i}`)"
            v-bind="component.attrs"
            v-model="attributesModel[i]"
            required
            @input="onRemoveError(`attributes.${i}`)"
          >{{ component.label }}</component>
        </div>

        <actions-button
          appearance="primary"
          class="fill"
          :disabled="isLogging"
          :loading="isLogging"
          @click="onConfirm"
        >{{ $t('user-portal.action_confirm_send_email') }}</actions-button>
      </section>

       <!-- Success -->
      <section 
        class="view-claim-auth__login flow" 
        v-if="done">
        <ui-banner
          class="layout-claim__banner"
          :intent="$pepper.Intent.SUCCESS"
          :title="$t('user-portal.nft_self_claim_success')">
          <template #description>
            <div class="layout-claim__banner-descr">
              <p v-html="$t('user-portal.nft_self_claim_success_description')"></p>
            </div>
          </template>
        </ui-banner>
      </section>
    </form>
  </div>
</template>

<script>
import MixinClaimError from '@/modules/claim/helpers/errors.js'
import MixinError from '@/helpers/errors'
import MixinRouteLeave from '@/modules/claim/helpers/router-leave.js'
import MixinSelfClaim from '@/modules/claim/helpers/self-claim.js'

import UiBanner from '@/components/ui/banner.vue'

import FormsLogin from '@/modules/user/components/forms/login'
import FormsLostPassword from '@/modules/user/components/forms/lost-password'

export default {
  name: 'ClaimLoginView',

  inject: [
    '$user',
    '$localStorage',

    '$redirect'
  ],

  components: {
    FormsLogin,
    FormsLostPassword,
    UiBanner,
  },

  mixins: [
    MixinError,
    MixinClaimError,
    MixinRouteLeave,
    MixinSelfClaim
  ],

  data() {
    return {
      errors: {},
      key: 1,
      isLogging: false,
      email: null,
      done: false,
      attributesModel: []
    }
  },

  computed: {
    attributesComponents() {
      return this.nft.attributes.map((a) => {
        const c = {
          is: 'forms-input',
          key: a.position,
          label: a.label,
          attrs: {}
        }
        switch (a.type) {
          case 'stat' :
            c.attrs.type = 'number'
            c.attrs.step = '1'
            c.attrs.min = '0'
            break
          case 'boost' :
            c.attrs.type = 'number'
            c.attrs.step = '1'
            c.attrs.min = '1'
            break
        }
        return c
      })
    },

    emailErrors() {
      let ret = [
        ...this.getErrors('nft_id'),
        ...this.getErrors('email')
      ]

      return ret.length > 0 ? ret : []
    },

    user() {
      return this.$user.user
    },
  },

  methods: {
    onKeyUp(ev) {
      if(ev.code === 'Enter') {
        this.onConfirm()
      }
    },

    async onConfirm(e) {
      try {
        this.isLogging = true
        this.errors = {}
        this.claimError = {}
        this.hasError = false

        let payload = {
          nft_id: this.$route.params.id,
          email: this.email,
          attributes: this.attributesModel
        }

        await this.$nft.selfClaim(payload)
        await this.$user.init({})

        this.done = true
      } catch(e) {
        this.handleErrors(e)
        this.handleClaimErrors(e)
      } finally {
        this.isLogging = false
        this.key++
      }
    },

    onRemoveEmailErrors() {
      this.onRemoveError('nft_id')
      this.onRemoveError('email')
    },
  },

  mounted() {
    this.errors = {}
    this.claimError = {}
    this.lostPassword = false
    
    let email = sessionStorage.getItem('email')
    if(email) {
      this.email = email
    }

    if(this.$user.user.isLogged) {
      this.email = this.$user.user.email
    }

    if (this.nft && this.nft.claimableAttributesMode === 'user') {
      this.attributesModel = this.nft.attributes.map((a) => {
        switch (a.type) {
          case 'property':
            return ''
          case 'stat':
            return '0'
          case 'boost':
            return '1'
        }
      })
    }
  }
}

</script>